body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.map-img {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 1.5rem 0;
}

.map-selected {
  fill: #699EAA;
  /* fill: #E3DA37; */
}

.map-unselected {
  fill: #000;
}

/* .map-selected:hover, .map-unselected:hover {
  cursor: pointer;
} */

body[dir='rtl'] .MuiInputLabel-outlined {
  transform: translate(-14px, 20px) scale(1) !important;
}

body[dir='rtl'] .MuiInputLabel-shrink {
  transform-origin: top right !important;
  transform: translate(-14px, -6px) scale(0.75) !important;
}

body[dir='rtl'] .MuiInputLabel-formControl {
  left: unset !important;
  right: 0 !important;
}

body[dir='rtl'] .MuiOutlinedInput-notchedOutline legend {
  text-align: right !important;
}
